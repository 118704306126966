import React from "react";
import { Component, createRef } from "react";

import Button from "../Components/Button";
import main from "../Scripts/main";
import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";
import Warning from "../Components/Warning";

const roles = ["Observateur", "Editeur", "Administrateur"];

class Admin extends Component{
    constructor(){
        super();
        main.askForUsers();
        this.warning = createRef();
        this.toBeDeleted = undefined;
        this.addUserName = createRef();
        this.addUserPwd = createRef();
        this.addUserRank = createRef();
        this.openAddUserBtn = createRef();
        this.openChangeTemplateBtn = createRef();
        this.listTemplate = createRef();
        this.invoiceTemplate = createRef();
        this.listText = createRef();
        this.invoiceText = createRef();
    }

    updateRole(user, event){
        main.updateRole(user, parseInt(event.target.value));
    }

    deleteUser(){
        main.removeUser(this.toBeDeleted);
    }

    deleteUserWindow(user){
        this.toBeDeleted = user;
        this.warning.current.btn.current.click();
    }

    addUser(){
        main.addUser(this.addUserName.current.value, this.addUserPwd.current.value, parseInt(this.addUserRank.current.value));
        this.clearAddUser();
    }

    clearAddUser(){
        this.addUserName.current.value = "";
        this.addUserPwd.current.value = "";
        this.addUserRank.current.value = "1";
    }

    openAddUser(){
        this.openAddUserBtn.current.click();
    }

    openChangeTemplate(){
        this.openChangeTemplateBtn.current.click();
    }

    async changeTemplate(){
        var list;
        var invoice;
        if (this.listTemplate.current.files[0] != undefined){
            list = await this.listTemplate.current.files[0].arrayBuffer();
        }
        if (this.invoiceTemplate.current.files[0] != undefined){
            invoice = await this.invoiceTemplate.current.files[0].arrayBuffer();
        }
        main.changeTemplates(list, invoice);
        this.clearChangeTemplate();
        console.log("done")
    }

    clearChangeTemplate(){
        this.invoiceTemplate.current.value = "";
        this.listTemplate.current.value = "";
        this.invoiceText.current.innerHTML = "Choisir le fichier"
        this.listText.current.innerHTML = "Choisir le fichier"
        console.log("supprrr")
        console.log(this.invoiceTemplate.current.files[0]);
    }

    updateListTemplate(){
        this.listText.current.innerHTML = this.listTemplate.current.files[0].name;
    }

    updateInvoiceTemplate(){
        this.invoiceText.current.innerHTML = this.invoiceTemplate.current.files[0].name;
    }

    render(){
        var buttons = [
            {
                text: "Accueil",
                link: "/"
            },
            {
                text: "Ajouter un utilisateur",
                click: this.openAddUser.bind(this)
            },
            {
                text: "Changer template Excel",
                click: this.openChangeTemplate.bind(this)
            }
        ];
        var lines = [];
        var users = main.getUsers();
        for (var user in users){
            lines.push(
                <tr key={user}>
                    <td>{user}</td>
                    <td>
                        <center>
                            <select 
                                value={users[user].rank.toString()}
                                className="form-control"
                                style={{width: 180}}
                                onChange={this.updateRole.bind(this, user)}
                            >
                                <option value="1">Observateur</option>
                                <option value="2">Editeur</option>
                                <option value="3">Administrateur</option>
                            </select>
                        </center>
                    </td>
                    <td>
                        <a
                            className="closeCross"
                            onClick={this.deleteUserWindow.bind(this, user)}
                        >
                            X
                        </a>
                    </td>
                </tr>
            );
        }
        return (
            <div>
                <NavBar>
                    <Menu buttons={buttons}/>
                </NavBar>
                <center>
                    <div className="usersList">
                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Utilisateur</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody className="table-group-divider">
                                {lines}
                            </tbody>
                        </table>
                    </div>
                </center>
                <button ref={this.openAddUserBtn} type="button" className="btn btn-primary" data-toggle="modal" data-target="#addUserModal" style={{display:"none"}}></button>
                <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="addUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addUserModalLabel">Ajouter un utilisateur</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form><center>
                                <div className="form-group">
                                    <label htmlFor="addUserInputEmail1">Nom d'utilisateur</label>
                                    <input ref={this.addUserName} style={{width: 250}} type="email" className="form-control" id="addUserInputEmail1" placeholder="Entrez un nom d'utilisateur"/>                                </div>
                                <div className="form-group">
                                    <label htmlFor="addUserInputPassword1">Mot de passe</label>
                                    <input ref={this.addUserPwd} style={{width: 250}} type="text" className="form-control" id="addUserInputPassword1" aria-describedby="passHelp" placeholder="Entrez un mot de passe"/>
                                    <small id="passHelp" className="form-text text-muted">L'utilisateur pourra choisir son propre mot de passe une fois connecté</small>
                                </div>
                                <div className="form-group">
                                <label htmlFor="addUserSelect">Role</label>
                                    <select 
                                        className="form-control"
                                        style={{width: 250}}
                                        id="addUserSelect"
                                        ref={this.addUserRank}
                                    >
                                        <option value="1">Observateur</option>
                                        <option value="2">Editeur</option>
                                        <option value="3">Administrateur</option>
                                </select>
                                </div></center>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.clearAddUser.bind(this)}>Annuler</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.addUser.bind(this)}>Enregistrer</button>
                        </div>
                        </div>
                    </div>
                </div>
                <Warning ref={this.warning} ok={this.deleteUser.bind(this)} okText="Supprimer">
                    Etes vous sur de vouloir supprimer cet utilisateur ?
                </Warning>
                <button ref={this.openChangeTemplateBtn} type="button" className="btn btn-primary" data-toggle="modal" data-target="#changeTemplateModal" style={{display:"none"}}></button>
                <div className="modal fade" id="changeTemplateModal" tabIndex="-1" role="dialog" aria-labelledby="changeTemplateModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="changeTemplateModalLabel">Modifier les templates Excel</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <center>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Bordereau</span>
                                    </div>
                                    <div className="custom-file">
                                        <input ref={this.invoiceTemplate} onChange={this.updateInvoiceTemplate.bind(this)} type="file" className="custom-file-input" id="inputGroupFile01" accept=".xlsx"/>
                                        <label style={{textAlign: "left"}} ref={this.invoiceText} className="custom-file-label" htmlFor="inputGroupFile01">Choisir le fichier</label>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Liste des échafaudages</span>
                                    </div>
                                    <div className="custom-file">
                                        <input ref={this.listTemplate} onChange={this.updateListTemplate.bind(this)} type="file" className="custom-file-input" id="inputGroupFile02" accept=".xlsx"/>
                                        <label style={{textAlign: "left"}} ref={this.listText} className="custom-file-label" htmlFor="inputGroupFile02">Choisir le fichier</label>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.clearChangeTemplate.bind(this)}>Annuler</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.changeTemplate.bind(this)}>Enregistrer</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Admin;