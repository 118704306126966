import React from "react";
import { Component } from "react";
import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";


class ServerUnavailable extends Component {
    constructor(){
        super();
    }

    render(){
        return (
            <div>
                <NavBar>
                </NavBar>
                <h1>Erreur : Serveur indisponible</h1>
            </div>
        )
    }
}

export default ServerUnavailable;