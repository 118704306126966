import React, { createRef } from "react";
import {Component} from "react";

import Menu from "../Components/Menu";
import main from "../Scripts/main";
import Input from "../Components/Input";
import Select from "../Components/Select";
import TextArea from "../Components/TextArea";
import NavBar from "../Components/NavBar";
import generateInvoice from "../Scripts/generateInvoice";
import SavePopup from "../Components/SavePopup";
import Date from "../Components/Date";
import Warning from "../Components/Warning";

class EditScaf extends Component{

    constructor(){
        super();

        const id = window.location.href.split('/')[4];
        var scaf = {
            id: undefined,
            v: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        };

        this.state = {
            scaf: scaf,
            id: id
        }

        this.download = createRef();
        this.popup = createRef();
        this.deleteWarning = createRef();

        main.initEditScaf(this);

    }

    updateData(){
        if (window.location.href.split('/')[3] == "editScaf"){
            var scaf = main.getScafBtId(this.state.id)
            if (scaf != null){
                this.setState({scaf:scaf});
            }else{
                console.log("scaf dosent exist")
            }
        }
    }

    componentDidMount(){
        this.updateData();
    }

    async export(){
        console.log("export");
        const fileOutput = await generateInvoice(this.state.scaf);
        this.download.current.download = this.popup.current.filename.current.value + ".xlsx";
        this.download.current.href = "data:application/xlsx;base64," + fileOutput;
        this.download.current.click();
    }

    openExport(){
        this.popup.current.filename.current.value = "";
        this.popup.current.btn.current.click();
    }

    openDelete(){
        this.deleteWarning.current.btn.current.click();
    }

    delete(){
        console.log("delete" + this.state.scaf.id);
        main.deleteScaf(this.state.scaf.id);
        main.openLink("/");
    }

    render(){
        var buttons = [
            {
                text: "Retour",
                link: "/"
            },
            {
                text: "Générer le borderau",
                click: this.openExport.bind(this)
            },
            {
                text: "Supprimer",
                click: this.openDelete.bind(this)
            }
        ];
        return (
            <div>
                <NavBar>
                    <Menu buttons={buttons}/>
                </NavBar>
                <div className="container text-center"
                style={{
                    marginTop:50
                }}>
                    <div className="row">
                        <Select name="Site" value={this.state.scaf.v[32]} id={this.state.scaf.id} field="32">
                            <option>BURGO</option>
                        </Select>
                        <Input name="Longueur" t="num" value={this.state.scaf.v[10]} id={this.state.scaf.id} field="10"/>
                        <Select name="Complexité" value={this.state.scaf.v[16]} id={this.state.scaf.id} field="16">
                            <option>1</option>
                            <option>1.1</option>
                            <option>1.2</option>
                            <option>1.6</option>
                            <option>2</option>
                        </Select>
                        <Date name="Date Montage" value={this.state.scaf.v[9]} id={this.state.scaf.id} field="9"/>
                    </div>
                    <div className="row">
                        <Input name="N° échafaudage" value={this.state.scaf.v[1]} id={this.state.scaf.id} field="1"/>
                        <Input name="Largeur" t="num" value={this.state.scaf.v[11]} id={this.state.scaf.id} field="11"/>
                        <Input name="Nb heures ARI" t="num" value={this.state.scaf.v[17]} id={this.state.scaf.id} field="17"/>
                        <Date name="Date Démontage" value={this.state.scaf.v[23]} id={this.state.scaf.id} field="23"/>
                    </div>
                    <div className="row">
                        <Input name="Client" value={this.state.scaf.v[3]} id={this.state.scaf.id} field="3"/>
                        <Input name="Hauteur" t="num" value={this.state.scaf.v[12]} id={this.state.scaf.id} field="12"/>
                        <Input name="Nb heures modif" t="num" value={this.state.scaf.v[18]} id={this.state.scaf.id} field="18"/>
                        <Date name="Date Démonage En cours" value={this.state.scaf.v[27]} id={this.state.scaf.id} field="27"/>
                    </div>
                    <div className="row">
                        <Input name="N°OT" value={this.state.scaf.v[2]} id={this.state.scaf.id} field="2"/>
                        <Input name="Nb Planches Supp" t="num" value={this.state.scaf.v[13]} id={this.state.scaf.id} field="13"/>
                        <Input name="Plus Value Samedi" t="num" value={this.state.scaf.v[19]} id={this.state.scaf.id} field="19"/>
                        <Select name="Statut" value={this.state.scaf.v[22]} id={this.state.scaf.id} field="22">
                            <option>MONTE</option>
                            <option>DEMONTE</option>
                        </Select>
                    </div>
                    <div className="row">
                        <Input name="Zone" value={this.state.scaf.v[4]} id={this.state.scaf.id} field="4"/>
                        <Input name="Surface Baches" t="num" value={this.state.scaf.v[14]} id={this.state.scaf.id} field="14"/>
                        <Input name="Plus Value nuit" t="num" value={this.state.scaf.v[20]} id={this.state.scaf.id} field="20"/>
                        <Select name="Loc en Cours" value={this.state.scaf.v[29]} id={this.state.scaf.id} field="29">
                            <option>OUI</option>
                            <option>NON</option>
                        </Select>
                    </div>
                    <div className="row">
                        <Input name="Appareil" value={this.state.scaf.v[5]} id={this.state.scaf.id} field="5"/>
                        <Input name="Surface Filets" t="num" value={this.state.scaf.v[15]} id={this.state.scaf.id} field="15"/>
                        <Input name="Plus Value Dimanche et JF" t="num" value={this.state.scaf.v[21]} id={this.state.scaf.id} field="21"/>
                        <Select name="Type" value={this.state.scaf.v[7]} id={this.state.scaf.id} field="7">
                            <option>BORDEREAU</option>
                            <option>FORFAIT</option>
                        </Select>
                    </div>
                    <div className="row">
                        <Input name="Donneur d'ordre" value={this.state.scaf.v[6]} id={this.state.scaf.id} field="6"/>
                        <TextArea name="Libellé" value={this.state.scaf.v[8]} id={this.state.scaf.id} field="8"/>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                </div>
                <SavePopup ref={this.popup} ok={this.export.bind(this)} />
                <Warning ref={this.deleteWarning} ok={this.delete.bind(this)} okText="Supprimer">
                    Etes vous sur de vouloir supprimer cet échafaudage ?
                </Warning>
                <a ref={this.download} style={{display: "none"}} download="file.xlsx" href="data:application/xlsx;base64,">Download</a>
            </div>
        )
    }
}

export default EditScaf;