import React from "react";
import {Component} from "react";

import Value from "../Components/Value";
import main from "../Scripts/main";
import { Link } from "react-router-dom";

class NavBar extends Component{
    constructor(){
        super();
    }//<Link to={"./editScaf/" + this.props.id}>

    changePassword(){
        main.openLink("changePassword");
    }

    disconnect(){
        main.disconnect();
    }

    admin(){
        main.openLink("admin");
    }

    render(){
        var values = [];
        for (var i in this.props.values){
            values.push(
                <Value value={this.props.values[i]} key={i}/>
            );
        }
        const disable = main.getRank() < 3;
        const disconnected = main.getRank() == 0;
        return (
            <nav className="navbar navbar-dark bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand">
                        <img src="kaefer.png" className="logoNav" alt="" width="160" height="42"/>
                    </a>
                    <div style={{
                        left: 220,
                        right: 220,
                        position: "absolute"
                    }}
                    >
                        {this.props.children}
                    </div>
                    <div className="btn-group">
                        <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="user.png" alt="" width="30" height="30"/>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <li><h6 className="dropdown-header">Paramètres utilisateur</h6></li>
                            <button className="dropdown-item" type="button" onClick={this.changePassword} disabled={disconnected}>Changer de mot de passe</button>
                            <button className="dropdown-item" type="button" onClick={this.admin} disabled={disable}>Espace administration</button>
                            <li><hr className="dropdown-divider"/></li>
                            <button className="dropdown-item" type="button" onClick={this.disconnect} disabled={disconnected}>Déconnexion</button>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;