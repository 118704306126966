import React from "react";
import {Component} from "react";

import main from "../Scripts/main";

class Input extends Component{
    constructor(){
        super();
    }
    
    change(event){
        if (main.getRank() < 2) return;
        var val = event.target.value;
        if (this.props.t == "num") val = val.split("").filter(c => "1234567890.".includes(c)).join("");
        main.updateValue(this.props.id, this.props.field, val);
    }

    render(){
        return (
            <div
                className="col"
            >
                <a>{this.props.name}</a><br/>
                <input id={this.props.name} className="form-control" type="text" onChange={this.change.bind(this)} value={this.props.value}/>
            </div>
        )
    }
}

export default Input;