import React from "react";
import { Component, createRef } from "react";

import Button from "../Components/Button";
import main from "../Scripts/main";
import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";

class ChangePassword extends Component{
    constructor(){
        super();
        this.oldPwd = createRef();
        this.newPwd = createRef();
        this.rptPwd = createRef();
        this.state = {
            msg : ""
        };
    }

    click(){
        if (this.newPwd.current.value.length < 8 ){
            this.setState({
                msg: "Votre mot de passe doit contenir au moins 8 caractères"
            });
            window.history.pushState(null, "", "/changePassword");
        }else if (this.newPwd.current.value != this.rptPwd.current.value){
            this.setState({
                msg: "Les 2 mot de passes ne correspondent pas"
            });
            window.history.pushState(null, "", "/changePassword");
        }else{
            main.changePassword(this.oldPwd.current.value, this.newPwd.current.value);
            this.setState({
                msg: ""
            });
            window.history.pushState(null, "", "/changePassword");
        }
        console.log(this.oldPwd.current.value);
    }

    render(){
        var msg;
        const msgI = window.location.href.split('/')[4];
        if (msgI == "wp") msg = "Mot de passe incorrect";
        if (msgI == "success"){
            msg = "Votre mot de passe a bien été changé";
            this.oldPwd.current.value = "";
            this.newPwd.current.value = "";
            this.rptPwd.current.value = "";
        }
        var buttons = [
            {
                text: "Accueil",
                link: "/"
            }
        ];
        return (
            <div>
                <NavBar>
                    <Menu buttons={buttons}/>
                </NavBar>
                <center>
                    <div className="connectionDiv">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Mot de Passe</label>
                            <input type="password" className="form-control" ref={this.oldPwd} id="username" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Nouveau Mot de Passe</label>
                            <input type="password" ref={this.newPwd} className="form-control" id="password"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Répéter Nouveau Mot de Passe</label>
                            <input type="password" ref={this.rptPwd} className="form-control" id="password"/>
                        </div>
                        <button className="btn btn-primary" onClick={this.click.bind(this)}>Valider</button>
                    </div>
                </center>
                <a
                    style={{
                        marginTop: 30,
                        display: "inline-block"
                    }}
                >{msg || this.state.msg}</a>
            </div>
        )
    }
}

export default ChangePassword;