import Excel from "exceljs";
import main from "./main";

const listCells = [0, "D7", "D9", "A7", "D6", "D8", "D5", 0, "B10", 9,"A17","B17","C17","A22","B26","B30","A36","B51","B45","B46","B47","B48", 22, 23,"O39","O38","O30","O31","O32","O33","O34","O35","O37","O36",29,"H43"];

async function wait(time){
    return await new Promise(r => setTimeout(r, time));
}

function smartParse(val){
    const isNum = (v) => {
        var dp = false;
        for (var e of v){
            if (!"0123456789.".includes(e)) return false;
            if (e == "."){
                if (dp) return false;
                dp = true;
            }
        }
        return true;
    }
    if (val == undefined || val == null) return "";
    if (typeof(val) == "number") return val;
    if (val == "") return "";
    var stringed = val.toString();
    if (isNum(stringed)) return parseFloat(stringed);
    return stringed;
}

async function generateRow(scaf){
    const site = scaf.v[32] || "BURGO";
    const ew = await main.getExcelWorkbook(scaf);
    var values = {};
    for (var i in main.getDataMap(site)){
        const e = main.getDataMap(site)[i];
        if (!e.calc && e.cell != "") values[e.cell] = scaf.v[i];
    }
    ew.setValue(values);
    var res = [];
    for (var c of listCells){
        if (typeof(c) == "number"){//it's a list field id
            res.push(scaf.v[c]);
        }else if (c != null){//it's a cell id
            res.push(ew.getValue(c));
        }
    }
    return res;
}

async function generateList(list){
    console.log("starting")
    const workbook = new Excel.Workbook();
    var data = main.getListTemplate();
    while (data == undefined){
        await wait(2000)
        data = main.getListTemplate();
    }
    await workbook.xlsx.load(data);
    for (var i in list){
        var rowVal = await generateRow(list[i])
        var row = workbook.worksheets[0].getRow(8+parseInt(i));
        for (var ii in rowVal){
            row.getCell(parseInt(ii)+2).value = smartParse(rowVal[ii]);
        }
    }
    //workbook.worksheets[0].getCell("D9").value = list[3].v[1];
    //probleme de couleur a moitié resolu mais a checker quand meme
    const res = await workbook.xlsx.writeBuffer()
    return res.toString("base64");
}

export default generateList;