import React from "react";
import { Component, createRef } from "react";

import Button from "../Components/Button";
import main from "../Scripts/main";
import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";

class Reconnect extends Component{
    constructor(){
        super();
    }

    click(){
        main.reconnect();
    }

    render(){
        return (
            <div>
                <NavBar>
                </NavBar>
                <a>Vous avez été déconnecté car vos identifiants ont été utilisés sur une autre instance</a><br/>
                <Button text="Se reconnecter" click={this.click.bind(this)} /><br/>
            </div>
        )
    }
}

export default Reconnect;