import React, { createRef } from "react";
import {Component} from "react";

import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";
import Table from "../Components/Table";
import generateList from "../Scripts/generateList";
import main from "../Scripts/main";
import SavePopup from "../Components/SavePopup";

class Main extends Component{
    constructor(){
        super();
        this.searchList = null;
        this.download = createRef();
        this.popup = createRef();
    }

    search(event){
        if (event.target.value.length > 0){
            this.searchList = [];
            for (var e of this.props.dataList){
                for (var ee of e.v){
                    var v = "";
                    try {v = ee.toString();}catch(e){}
                    if (v.toLowerCase().includes(event.target.value.toLowerCase())){
                        this.searchList.push(e);
                        break;
                    }
                }
            }
        }else{
            this.searchList = null;
        }
        this.forceUpdate();
    }

    async export(){
        console.log("someting here")
        var displayedList;
        if (this.searchList == null){
            displayedList = this.props.dataList;
        }else{
            displayedList = this.searchList;
        }
        console.log("export");
        const fileOutput = await generateList(displayedList);
        this.download.current.download = this.popup.current.filename.current.value + ".xlsx";
        this.download.current.href = "data:application/xlsx;base64," + fileOutput;
        this.download.current.click();
        //après on export displayed list comme ca ca permet de considérer les recherches dans l'export
    }

    openExport(){
        this.popup.current.filename.current.value = "";
        this.popup.current.btn.current.click();
    }

    render(){
        if (main.getRank() == 0) return (<div></div>);
        var buttons = [];
        if (main.getRank() > 1){
            buttons = [
                {
                    text: "Ajouter un échafaudage",
                    click: main.addScaf
                }
            ];
        }
        buttons.push({
            text: "Exporter",
            click: this.openExport.bind(this)
        });
        var displayedList;
        if (this.searchList == null){
            displayedList = this.props.dataList;
        }else{
            displayedList = this.searchList;
        }
        return (
            <div>
                <NavBar>
                    <span style={{
                        marginRight:50
                    }}>
                        <Menu buttons={buttons}/>
                    </span>
                    <span>
                        <form role="search" style={{display:"inline-block"}}>
                            <input className="form-control me-2" onChange={this.search.bind(this)} type="search" placeholder="Search" aria-label="Search"/>
                        </form>
                    </span>
                </NavBar>
                <Table dataList={displayedList}/>
                <SavePopup ref={this.popup} ok={this.export.bind(this)} />
                <a ref={this.download} style={{display: "none"}} download="file.xlsx" href="data:application/xlsx;base64,">Download</a>
            </div>
        )
    }
}

export default Main;