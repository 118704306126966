import io from "socket.io-client";
import RSAKey from "react-native-rsa";
import ExcelWorkbook from "./processFormulas";
import Excel from "exceljs";

var App;
var EditScaf;
var socket = io("http://kaeferechaf.loicbigeard.eu:8005");
//var socket = io("http://localhost:8005");
const dataMaps = {"BURGO":[//calc == readonly
    {title:"Chrono", calc:false, cell:""},
    {title:"N° Echaf", calc:false, cell:"D7"},
    {title:"N° OT", calc:false, cell:"D9"},
    {title:"Client", calc:false, cell:"A7"},
    {title:"Zone", calc:false, cell:"D6"},
    {title:"Appareil", calc:false, cell:"D8"},
    {title:"Donneur d'ordre", calc:false, cell:"D5"},
    {title:"TYPE", calc:false, cell:""},
    {title:"Libellé", calc:false, cell:"B10"},
    {title:"Date Montage", calc:false, cell:"D34"},
    {title:"Longueure", calc:false, cell:"A17"},
    {title:"Largeur", calc:false, cell:"B17"},
    {title:"Hauteur garde corps", calc:false, cell:"C17"},
    {title:"Nb de pl de travail sup", calc:false, cell:"A22"},
    {title:"Suface baches", calc:false, cell:"B26"},
    {title:"Surface filets", calc:false, cell:"B30"},
    {title:"Complexité", calc:false, cell:"A36"},
    {title:"Nb Heures avec ARI", calc:false, cell:"B51"},
    {title:"Nb H modif", calc:false, cell:"B45"},
    {title:"Plus value Samedi", calc:false, cell:"B46"},
    {title:"Plus value Nuit", calc:false, cell:"B47"},
    {title:"Plus value Dim et JF", calc:false, cell:"B48"},
    {title:"Statut", calc:false, cell:""},
    {title:"Date démontage", calc:false, cell:"D35"},
    {title:"Nb de points Loc", calc:true, cell:"O38"},
    {title:"Nb de points Echaf", calc:true, cell:"O39"},
    {title:"Montant facturation", calc:true, cell:"O35"},
    {title:"Date En cours", calc:false, cell:"H30", read:"O37"},
    {title:"Encours", calc:true, cell:"O36"},
    {title:"Location en cours", calc:false, cell:""},
    {title:"Tonnage", calc:true, cell:"H43"},
    {title:"Observations", calc:false, cell:""},
    {title:"Site", calc:false, cell:""}
]};//index of each value is it's index in the array
var excelWorkbooks = {}
var rank = 0;
var dataList = [];
var startupRunned = false;
var users = {};
var invoiceTemplates = {};
var listTemplate;
var sites;
//var randomToken;
const bits = 1024;
const exponent = '10001'; // must be a string
var xKey;
var exchangeToken;

socket.on("connected", (rsakey) => {
    xKey = generatePrimaryXKey();
    const rsa = new RSAKey();
    rsa.generate(bits, exponent);
    rsa.setPublicString(rsakey); // return json encoded string
    const data = rsa.encrypt(xKey);
    socket.emit("xKey", data);
});

socket.on("attempLogin", () => {
    //randomToken = val;
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    if (token != null && username != null){
        socket.emit("tokenLogin", encode(username), encode(token));
    }else{
        //pas ici ca ril faut mettre cette partie quand on est connecté et que startup a été exec.....
        //socket.emit("login", "admin", ("password".hashCode().toString() + randomToken).hashCode(), true);
        //localStorage.setItem("username", "admin");
        main.openLink("/connection");
    }
});

socket.on("disconnect", (reason) => {
    if (reason == "io server disconnect"){
        main.openLink("/reconnect");
    }else if (reason == "io client disconnect"){
        main.openLink("/connection/wt");
    }
    else if (reason == "ping timeout"){
        main.openLink("/serverUnavailable");
    }
    else if (reason == "transport close"){
        main.openLink("/serverUnavailable");
    }
    else if (reason == "transport error"){
        main.openLink("/serverUnavailable");
    }
    dataList = [];
    rank = 0;
    users = {};
    exchangeToken = undefined;
    console.log(reason);
});

socket.on("connect_error", () => {
    main.openLink("/serverUnavailable");
})

socket.on("loggedIn", (xoreddata) => {
    console.log(xoreddata)
    console.log(decode(xoreddata))
    var data = JSON.parse(decode(xoreddata));
    exchangeToken = data.exchangeToken;
    console.log(data);
    main.openLink("/");
    socket.emit("getData", generateValidationKey());
    console.log("ok")
    rank = data.rank;
    if (data.token != null){
        localStorage.setItem("token", data.token.toString());
    }
    if (rank >= 2){
        socket.emit("getSites", generateValidationKey());
    }
    setInterval(() => {
        //console.log(dataList)
        var stringedData = JSON.stringify(dataList)
        if (stringedData == "[]"){
            socket.emit("getData", generateValidationKey());
        }else{
            var hash = stringedData.hashCode();
            socket.emit("checkHash", hash);
        }
    }, 300000);//every 5 minutes (300s)
});

socket.on("wrongToken", () => {
    localStorage.removeItem("token");
    main.openLink("/connection/wt");
});

socket.on("wrongUsername", () => {
    localStorage.removeItem("username");
    main.openLink("/connection/wu");
});

socket.on("wrongPassword", () => {
    main.openLink("/connection/wp");
});

socket.on("invoiceTemplate", (data, s) => {
    const site = decode(s);
    invoiceTemplates[site] = str2ab(decode(data));
});

socket.on("listTemplate", (data) => {
    listTemplate = str2ab(decode(data));
});

socket.on("serverError", () => {
    console.log("server error");
   // main.alert("Une erreur serveur est survenue. Merci de tenter a nouveau votre action")
});

socket.on("userAdded", () => {
    socket.emit("getUsers", generateValidationKey());
    main.alert("Utilisateur ajouté avec succès")
});

socket.on("templatesChanged", () => {
    main.alert("Les templates Excel ont bien été édités");
});

socket.on("userAlredyExist", () => {
    main.alert("Un utilisateur avec ce nom existe déjà");
});

socket.on("userEditDone", () => {
    socket.emit("getUsers", generateValidationKey());
    //main.alert("Utilisateur modifié avec succès");
    //on met pas l'alerte de validation c'est beaucoup trop chiant
});

socket.on("userDosentExist", () => {
    main.alert("Cet utilisateur n'exsite pas");
});

socket.on("usersInfos", (data) => {
    users = JSON.parse(decode(data));
    main.forceUpdate();
});

socket.on("userDeleted", () => {
    socket.emit("getUsers", generateValidationKey());
});

socket.on("passwordChanged", () => {
    main.openLink("/changePassword/success");
});

socket.on("changeWrongPassword", () => {
    main.openLink("/changePassword/wp");
});

socket.on("dataList", (data) => {
    console.log("data")
    dataList = JSON.parse(decode(data));
    if (App != undefined) main.forceUpdate();
    if (EditScaf != undefined) EditScaf.updateData();
});

socket.on("hashResult", (res) => {
    if (!res){
        socket.emit("getData", generateValidationKey());
    }
});

socket.on("dataUpdate", (d) => {
    var data = JSON.parse(decode(d));
    updateDataList(data.id, data.i, data.value);
});

socket.on("addScafID", (id) => {
    dataList.push({
        id: id,
        v: [id, 0, 0, "", "", "", "", "BORDEREAU", "", "", 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, "MONTE", "", 0, 0, 0, "", 0, "OUI", 0, "", "BURGO"]
    });
    main.openLink("/editScaf/" + id);
});

socket.on("addedScafID", (id) => {
    dataList.push({
        id: id,
        v: [id, 0, 0, "", "", "", "", "BORDEREAU", "", "", 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, "MONTE", "", 0, 0, 0, "", 0, "OUI", 0, "", "BURGO"]
    });
    main.forceUpdate();
});

socket.on("sitesList", (data) => {
    sites = JSON.parse(decode(data));
    preloadExcelWorkbooks(sites);
});

socket.on("scafDeleted", (id) => {
    const dId = decode(id);
    const ind = getScafIndex(parseInt(dId));
    if (ind != null) dataList.splice(ind, 1);
    main.forceUpdate();
});

function startup(){
    if (startupRunned) return;
    startupRunned = true;
    
    //do someting
}

function updateDataList(id, i, value){
    for (var e of dataList){
        if (e.id == id){
            e.v[i] = value;
        }
    }
    main.forceUpdate();
    //console.log(App.state.dataList);
}

async function wait(time){
    return await new Promise(r => setTimeout(r, time));
}

function getInvoiceTemplateData(site){
    if (invoiceTemplates[site] == undefined) socket.emit("askInvoiceTemplate", encode(site), generateValidationKey());
    return invoiceTemplates[site];
}

async function getInvoiceTemplateFromSite(site){
    const workbook = new Excel.Workbook();
    var wbData = getInvoiceTemplateData(site);
    while (wbData == undefined){
        await wait(2000);
        wbData = getInvoiceTemplateData(site);
    }
    try{
        await workbook.xlsx.load(wbData);
    }catch(e){
        main.alert("Une erreur est survenue lors du chargement du template Excel. Reéssayez plus tard et vérifiez que le template est bien un fichier Excel");
        console.log("theres been an error with the fuckin template");
        console.log(e);
    }
    return workbook;
}

async function createExcelWorkbookFromTemplate(template, scaf){
    if (excelWorkbooks[template] != undefined) return;
    const workbook = await main.getInvoiceTemplate(scaf);
    excelWorkbooks[template] = new ExcelWorkbook(workbook.worksheets[0]);
}

async function createExcelWorkbookFromSite(site){
    if (excelWorkbooks[site] != undefined) return;
    const workbook = await getInvoiceTemplateFromSite(site);
    excelWorkbooks[site] = new ExcelWorkbook(workbook.worksheets[0]);
}

async function preloadExcelWorkbooks(lSites){
    for (var site of lSites){
        createExcelWorkbookFromSite(site);
    }
}

async function getExcelWorkbook(scaf){
    const site = scaf.v[32] || "BURGO";
    var sDate;
    if (![null, undefined, 0, ""].includes(scaf.v[23])){
        const spl = scaf.v[23].split("/");
        console.log(spl)
        const dateV = spl[2] + "-" + spl[1] + "-" + spl[0];
        console.log(dateV)
        sDate = new Date(dateV);
    }else if (![null, undefined, 0, ""].includes(scaf.v[27])){
        const spl = scaf.v[27].split("/");
        console.log(spl)
        const dateV = spl[2] + "-" + spl[1] + "-" + spl[0];
        console.log(dateV)
        sDate = new Date(dateV);
    }else{
        sDate = new Date();
    }
    const date = sDate.getTime();//timestamp date demontage ou today
    var template = "";
    for (var i=sites.length-1; i >= 0; i--){
        const [s, d] = sites[i].split("_");//name:timestamp
        if (site == s && date > parseInt(d)){
            template = sites[i];
            break;
        }
    }
    await createExcelWorkbookFromTemplate(template, scaf);
    return excelWorkbooks[template];
}

var main = {

    setAppVar(app){
        App = app;
        startup();
    },

    getDataList: () => {
        return dataList;
    },

    logApp(){
        console.log(App.state);
    },

    log(text){
        console.log(text);
    },

    setAppState(val){
        App.setState(val);
    },

    getScafBtId(id){
        try{
            for (var e of dataList){
                if (e.id == id){
                    return e;
                }
            }
            return null;
        }catch(err){
            return null;
        }
        
    },

    initEditScaf(e){
        EditScaf = e;
    },

    async updateValue(id, i, value){//called by editscaf
        EditScaf.state.scaf.v[i] = value;
        const site = EditScaf.state.scaf.v[32];
        console.log(value);
        if (id != undefined){
            updateDataList(id, i, value);
            socket.emit("updateValue", encode(JSON.stringify({id:id, i:i, value:value})), generateValidationKey());
            console.log("ok")
        }
        const dataMap = main.getDataMap(site);
        if (!dataMap[i].calc && dataMap[i].cell != ""){//if not a calculated value and is in the excel then can change the value of other
            //await createExcelWorkbook(site);//create the workbook only if not already cerated
            const ew = await getExcelWorkbook(EditScaf.state.scaf);
            var data = {};
            for (var ii in dataMap){
                const e = dataMap[ii];
                if (!e.calc && e.cell != "") data[e.cell] = EditScaf.state.scaf.v[ii];
            }
            ew.setValue(data);
            for (var ii in dataMap){
                const e = dataMap[ii];
                if (e.calc || e.read != undefined){
                    const nv = ew.getValue(e.read == undefined ? e.cell : e.read);
                    console.log(nv)
                    if (nv != EditScaf.state.scaf.v[ii] && nv != null) main.updateValue(id, ii, nv);
                }
            }
        }
    },

    connect(username, password, stayConnected){
        console.log(socket.connected)
        socket.emit("login", encode(username), encode(password.hashCode().toString()), stayConnected);
        localStorage.setItem("username", username);
        //socket.emit("login", username, password.hashCode());
    },

    disconnect(){
        socket.disconnect();
        localStorage.removeItem("token");
        socket.connect();
    },

    addScaf(){
        socket.emit("addScaf", generateValidationKey());
    },

    openLink(link){
        if (App != undefined){
            App.openLink(link);
        }else{
            var tmpLinkInterval = setInterval(() => {
                if (App != undefined){
                    console.log("executed")
                    App.openLink(link);
                    clearInterval(tmpLinkInterval);
                }
            }, 100);
        }
    },

    forceUpdate(){
        App.forceUpdate();
    },

    changePassword(oldPwd, newPwd){
        if (oldPwd != undefined && newPwd != undefined){
            socket.emit("changePassword", encode(oldPwd.hashCode().toString()), encode(newPwd.hashCode().toString()), generateValidationKey());
        }else{
            //print an error
        }
    },

    reconnect(){
        if (!socket.connected) socket.connect();
    },

    askForUsers(){
        socket.emit("getUsers", generateValidationKey());
    },

    getUsers(){
        return users;
    },

    updateRole(user, role){
        users[user].rank = role;
        main.forceUpdate();
        socket.emit("editUser", encode(JSON.stringify({
            username: user,
            rank: role
        })), generateValidationKey());
    },

    removeUser(user){
        socket.emit("removeUser", encode(user), generateValidationKey());
    },

    addUser(user, pwd, r){
        socket.emit("addUser", encode(JSON.stringify({
            username: user,
            data: {
                password: pwd.hashCode(),
                rank: r
            }
        })), generateValidationKey());
    },

    getRank(){
        return rank;
    },

    async getInvoiceTemplate(scaf){
        const workbook = new Excel.Workbook();
        const site = scaf.v[32] || "BURGO";
        var sDate;
        if (![null, undefined, 0, ""].includes(scaf.v[23])){
            const spl = scaf.v[23].split("/");
            console.log(spl)
            const dateV = spl[2] + "-" + spl[1] + "-" + spl[0];
            console.log(dateV)
            sDate = new Date(dateV);
        }else if (![null, undefined, 0, ""].includes(scaf.v[27])){
            const spl = scaf.v[27].split("/");
            console.log(spl)
            const dateV = spl[2] + "-" + spl[1] + "-" + spl[0];
            console.log(dateV)
            sDate = new Date(dateV);
        }else{
            sDate = new Date();
        }
        const date = sDate.getTime();//timestamp date demontage ou today
        var template = "";
        for (var i=sites.length-1; i >= 0; i--){
            const [s, d] = sites[i].split("_");//name:timestamp
            if (site == s && date > parseInt(d)){
                template = sites[i];
                break;
            }
        }
        var wbData = getInvoiceTemplateData(template);
        while (wbData == undefined){
            await wait(2000);
            wbData = getInvoiceTemplateData(template);
        }
        try{
            await workbook.xlsx.load(wbData);
        }catch(e){
            main.alert("Une erreur est survenue lors du chargement du template Excel. Reéssayez plus tard et vérifiez que le template est bien un fichier Excel");
            console.log("theres been an error with the fuckin template");
            console.log(e);
        }
        return workbook;
    },

    getListTemplate(){
        if (listTemplate == undefined) socket.emit("askListTemplate", generateValidationKey());
        return listTemplate;
    },

    changeTemplates(l, v){
        socket.emit("changeTemplates",
            encode(ab2str(l)),
            encode(ab2str(v)),
            generateValidationKey()
        );
    },

    alert(txt){
        App.alert(txt);
    },

    async getExcelWorkbook(scaf){
        return await getExcelWorkbook(scaf);
    },

    getDataMap(site){
        return dataMaps[site];
    },

    deleteScaf(id){
        socket.emit("deleteScaf", encode(id.toString()), generateValidationKey());
    }
}

function getScafIndex(id){
	for (var i in dataList){
		if (dataList[i].id == id){
			return i;
		}
	}
	return null;
}

String.prototype.hashCode = function() {
    var hash = 0;
    if (this.length == 0) {
        return hash;
    }
    for (var i = 0; i < this.length; i++) {
        var char = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}

function xorEncode(txt, pass) {
 
    var ord = [];
    var buf = "";
 
    for (var z = 1; z <= 255; z++) {ord[String.fromCharCode(z)] = z}
 
    for (var j = z = 0; z < txt.length; z++) {
        buf += String.fromCharCode(ord[txt.substr(z, 1)] ^ ord[pass.substr(j, 1)]);
        j = (j < pass.length) ? j + 1 : 0;
    }
 
    return buf;
 
}

function generatePrimaryXKey(){
    var res = "";
    for (var i = 0; i < 100; i++){
        res += String.fromCharCode(Math.round(Math.random()*127));
    }
    return res
}

function generateSecondaryXKey(xk, p){
    var res = "";
    for (var i = 0; i < 100; i++){
        var c = (xk + i.toString() + p + i.toString()).hashCode();
        res += String.fromCharCode(Math.abs(c)%128);
    }
    return res;
}

function decode(val){
    const spl = val.split(":");
    const p = parseInt(spl[spl.length-1]);
    const k = generateSecondaryXKey(xKey, p);
    var txt = "";
    for (var i=0; i < spl.length-1; i++){
        if (txt.length != 0) txt += ":";
        txt += spl[i];
    }
    return xorEncode(txt, k);
}

function encode(val){
    var res;
    do {
        const p = Math.round(Math.random()*10000000000000000).toString();
        const k = generateSecondaryXKey(xKey, p);
        res = xorEncode(val, k) + ":" + p;
    } while (decode(res) != val);
    return res;
}

function generateValidationKey(){
    const d = new Date().getTime();
    return encode((exchangeToken + d).toString() + ":" + d.toString());
}

function str2ab(str) {
    var buf = new ArrayBuffer(str.length); // 2 bytes for each char
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export default main;