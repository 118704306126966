import React from "react";
import {Component} from "react";

import main from "../Scripts/main";

class Date extends Component{
    constructor(){
        super();
    }
    
    change(event){
        const spl = event.target.value.split("-");
        console.log(""==event.target.value);
        if (main.getRank() < 2) return;
        if (event.target.value == "") {
            main.updateValue(this.props.id, this.props.field, "");
            return;
        }
        main.updateValue(this.props.id, this.props.field, spl[2] + "/" + spl[1] + "/" + spl[0]);
    }

    render(){
        var val;
        if (this.props.value != undefined && typeof(this.props.value) == "string"){
            const spl = this.props.value.split("/");
            val = spl[2] + "-" + spl[1] + "-" + spl[0];
        }
        return (
            <div
                className="col"
            >
                <a>{this.props.name}</a><br/>
                <input id={this.props.name} className="form-control" type="date" onChange={this.change.bind(this)} value={val}/>
            </div>
        )
    }
}

export default Date;