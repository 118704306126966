import React from "react";
import {Component} from "react";

function escapeHtml(text) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };
  
    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

class Value extends Component{
    constructor(){
        super();
    }

    render(){
        var value = "";
        try{// if something wierd happens value may not have a toString method....
            value = this.props.value.toString();
        }catch(e){}
        const val = value.substring(0, 15) + (value.length > 15 ? "..." : "");
        return (
            <td>{val == "" ? "-" : val.replace(/[ ]/g, '\u00a0')}</td>
        )
    }
}

export default Value;