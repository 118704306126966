import Excel from "exceljs";
import main from "./main";

async function wait(time){
    return await new Promise(r => setTimeout(r, time));
}

async function generateInvoice(scaf){
    const site = scaf.v[32];
    const workbook = await main.getInvoiceTemplate(scaf);
    const ew = await main.getExcelWorkbook(scaf);
    var values = {};
    for (var i in main.getDataMap(site)){
        const e = main.getDataMap(site)[i];
        if (!e.calc && e.cell != "") values[e.cell] = scaf.v[i];
    }
    ew.setValue(values);
    for (var e of main.getDataMap(site)){
        var c = e.cell;
        if (c != ""){
            workbook.worksheets[0].getCell(c).value = ew.getValue(c);
        }
    }
    for (var cell of ew.updatingCells){
        workbook.worksheets[0].getCell(cell).value = ew.getValue(cell);
    }
    //probleme de couleur a moitié resolu mais a checker quand meme
    const res = await workbook.xlsx.writeBuffer()
    return res.toString("base64");
}

export default generateInvoice;