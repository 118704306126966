import React, { createRef } from "react";
import {Component} from "react";

import Button from "../Components/Button";
import { Link } from "react-router-dom";

class Warning extends Component{
    constructor(){
        super();
        this.btn = createRef();
    }

    render(){
        return (
            <div>
                <button style={{display:"none"}} type="button" ref={this.btn} className="btn btn-primary" data-toggle="modal" data-target="#deleteUserWarning">
                Launch static backdrop modal
                </button>

                <div className="modal fade" id="deleteUserWarning" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Attention</h5>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.props.ok}>{this.props.okText}</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Warning;