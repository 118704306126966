import React from "react";
import {Component} from "react";

import Line from "../Components/Line";

class Table extends Component{
    constructor(){
        super();
    }

    render(){
        var lines = [];
        for (var e of this.props.dataList){
            lines.push(
                <Line id={e.id} key={e.id} values={e.v} click={() => {console.log("woula")}}/>
            );
        }
        return (
            <div className="list">
            <table className="table table-striped table-hover table-sm">
                <thead>
                    <tr>
                        <th scope="col">Chrono</th>
                        <th scope="col">N°&nbsp;Echaf</th>
                        <th scope="col">N°&nbsp;OT</th>
                        <th scope="col">Client</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Appareil</th>
                        <th scope="col">Donneur d'ordre</th>
                        <th scope="col">TYPE</th>
                        <th scope="col">Libellé</th>
                        <th scope="col">Date Montage</th>
                        <th scope="col">Longueure</th>
                        <th scope="col">Largeur</th>
                        <th scope="col">Hauteur garde&nbsp;corps</th>
                        <th scope="col">Nb&nbsp;de&nbsp;pl de&nbsp;travail&nbsp;sup</th>
                        <th scope="col">Suface baches</th>
                        <th scope="col">Surface filets</th>
                        <th scope="col">Complexité</th>
                        <th scope="col">Nb&nbsp;Heures avec&nbsp;ARI</th>
                        <th scope="col">Nb&nbsp;H modif</th>
                        <th scope="col">Plus&nbsp;value Samedi</th>
                        <th scope="col">Plus&nbsp;value Nuit</th>
                        <th scope="col">Plus&nbsp;value Dim&nbsp;et&nbsp;JF</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Date démontage</th>
                        <th scope="col">Nb&nbsp;de&nbsp;points Loc</th>
                        <th scope="col">Nb&nbsp;de&nbsp;points Echaf</th>
                        <th scope="col">Montant facturation</th>
                        <th scope="col">Date En&nbsp;cours</th>
                        <th scope="col">Encours</th>
                        <th scope="col">Location en&nbsp;cours</th>
                        <th scope="col">Tonnage</th>
                        <th scope="col">Observations</th>
                        <th scope="col">Site</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {lines}
                </tbody>
            </table>
            </div>
        )
    }
}

export default Table;