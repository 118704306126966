import React from "react";
import {Component} from "react";

import Button from "../Components/Button";
import { Link } from "react-router-dom";

class Menu extends Component{
    constructor(){
        super();
    }

    render(){
        var buttons = [];
        for (var e of this.props.buttons){
            if (e.link != undefined && e.link != null){
                buttons.push(
                    <Link key={e.link} to={e.link} className="btn btn-primary">{e.text}</Link>
                );
            }else{
                buttons.push(
                    <Button key={e.click.name}text={e.text} click={e.click}/>
                );
            }
        }
    
        return (
            <div 
                className="btn-group"
                style={{
                    marginBottom:5
            }}>
                {buttons}
            </div>
        )
    }
}

export default Menu;