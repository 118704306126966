import React, { createRef } from "react";
import {Component} from "react";

import Button from "../Components/Button";
import { Link } from "react-router-dom";

class SavePopup extends Component{
    constructor(){
        super();
        this.btn = createRef();
        this.filename = createRef();
    }

    render(){
        return (
            <div>
                <button style={{display:"none"}} type="button" ref={this.btn} className="btn btn-primary" data-toggle="modal" data-target="#savePopup">
                Launch static backdrop modal
                </button>

                <div className="modal fade" id="savePopup" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Enregistrer un fichier</h5>
                        </div>
                        <div className="modal-body">
                        <label className="sr-only" htmlFor="fileName">Nom du fichier</label>
                        <div className="input-group mb-2 mr-sm-2">
                            <input ref={this.filename} type="text" className="form-control" id="fileName" placeholder="Nom du fichier"/>
                            <div className="input-group-append">
                                <div className="input-group-text">.xlsx</div>
                            </div>
                        </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.props.ok}>Enregistrer</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SavePopup;