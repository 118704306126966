import React from "react";
import { Component, createRef } from "react";

import Button from "../Components/Button";
import main from "../Scripts/main";
import Menu from "../Components/Menu";
import NavBar from "../Components/NavBar";

class Connection extends Component{
    constructor(){
        super();
        this.username = createRef();
        this.password = createRef();
        this.askToken = createRef();
    }

    click(){
        main.connect(this.username.current.value, this.password.current.value, this.askToken.current.checked);
        console.log(this.username.current.value);
        console.log(this.askToken.current.checked)
    }

    render(){
        const msgs = {
            wp: "Le mot de passe que vous avez saisi est incorrect",
            wu: "Cet utilisateur n'existe pas",
            wt: "Vous avez été déconnecté, veuillez saisir a nouveau vos identifiants"
        };
        const msgI = window.location.href.split('/')[4];
        const msg = msgI == undefined ? "" : msgs[msgI];
        return (
            <div>
                <NavBar>
                </NavBar>
                <center>
                    <div className="connectionDiv">
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Identifiant</label>
                            <input type="text" className="form-control" ref={this.username} id="username" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Mot de Passe</label>
                            <input type="password" ref={this.password} className="form-control" id="password"/>
                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" ref={this.askToken}/>
                            <label className="switch" htmlFor="exampleCheck1">Rester connecté</label>
                        </div>
                        <button className="btn btn-primary" onClick={this.click.bind(this)}>Valider</button>
                    </div>
                </center>
                <a
                    style={{
                        marginTop: 30,
                        display: "inline-block"
                    }}
                >{msg}</a>
            </div>
        )
    }
}

export default Connection;