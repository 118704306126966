import { HyperFormula } from "hyperformula";
import Excel from "exceljs";

function parseCellAddress(address){
    var row = "";
    var colT = "";
    var middle = false;
    for (var e of address.toUpperCase()){
        if (!middle){//col
            if ("AZERTYUIOPQSDFGHJKLMWXCVBN".includes(e)){
                colT += e;
            }else if ("0123456789".includes(e)){
                middle = true;
                row += e;
            }else{
                return null;
            }
        }else{//row
            if ("0123456789".includes(e)){
                row += e;
            }else{
                return null;
            }
        }
    }
    var col = 0;
    for (var i = colT.length-1; i >= 0; i--){
        col += (colT[i].charCodeAt() - 64) * Math.pow(26, colT.length-1-i);
    }
    return {col:col-1, row:parseInt(row)-1};
}

function getCellAddress(col, row){
    var colRes = "";
    var rest = col+1;
    while (rest > 0){
        var t = (rest-1)%26;
        rest = (rest-1-t)/26;
        colRes = String.fromCharCode(65+t) + colRes;
    }
    return colRes + (row+1);
}

function formatTwoDigit(inp){
	if ((inp+"").length == 1){
		return "0" + inp;
	}
	return inp;
}

function ExcelDateToJSDate(serial) {
    if (serial == 0 || serial == undefined || serial.toString().includes("/")) return serial;
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
    return formatTwoDigit(date_info.getDate()) + "/" + formatTwoDigit(date_info.getMonth()+1) + "/" + date_info.getFullYear();
}

class ExcelWorkbook {
    constructor(worksheet){
        var array = [];
        this.updatingCells = [];
        const [r, c] = [worksheet.rowCount, worksheet.columnCount]
        for (var i=1; i <= r; i++){
            var e = worksheet.getRow(i);
            var row = []
            for (var ii=1; ii <= c; ii++){
                const v = e.getCell(ii).value;
                //if (v != null)console.log(v)
                if (typeof(v) == "object" && v != null){
                    if (v.formula == undefined){
                        row.push(v.result == undefined ? null : v.result)
                    }else{
                        row.push("=" + v.formula);
                        this.updatingCells.push(getCellAddress(ii-1, i-1));
                    }
                }else{
                    row.push(e.getCell(ii).value);
                }
            }
            array.push(row);
        }

        const options = {
            licenseKey: 'gpl-v3'
        };
        this.hfInstance = HyperFormula.buildFromArray(array, options);
    }

    getValue(cell){
        //value = {"C4":5,.....} def of all varibales needed for processing
        //cell = A3 => cell where the formula is

        const parsedCell = parseCellAddress(cell);
        if (parsedCell == null){
            return null;
        }

        var res = this.hfInstance.getCellValue({ col: parsedCell.col, row: parsedCell.row, sheet: 0 });
        if (res == null) res = "";
        if (this.hfInstance.getCellValueDetailedType({ col: parsedCell.col, row: parsedCell.row, sheet: 0 }) == "NUMBER_DATE") res = ExcelDateToJSDate(res);
        return typeof(res) == "object" ? "#VALUE!" : res;
    }

    setValue(value){
        this.hfInstance.suspendEvaluation();
        for (var c in value){
            const parsedCell = parseCellAddress(c);
            if (parsedCell == null){
                console.log("a")
                return null;
            }
            this.hfInstance.setCellContents({ col: parsedCell.col, row: parsedCell.row, sheet: 0 }, [[value[c].toString()]]);
        }
        this.hfInstance.resumeEvaluation();
    }
}

export default ExcelWorkbook