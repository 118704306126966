import React from "react";
import {Component} from "react";

import main from "../Scripts/main";

class Select extends Component{
    constructor(){
        super();
        this.state = {
            value: ""
        };
    }
    
    change(event){
        if (main.getRank() < 2) return;
        main.updateValue(this.props.id, this.props.field, event.target.value);
    }

    componentDidMount(){
        /*this.setState({
            value: this.props.value
        });*/
    }

    render(){
        return (
            <div
                className="col"
            >
                <a>{this.props.name}</a><br/>
                <select id={this.props.name} className="form-control" type="text" onChange={(e) => {this.change(e);}} value={this.props.value}>
                    {this.props.children}
                </select>
            </div>
        )
    }
}

export default Select;