import React from "react";
import {Component} from "react";

class Button extends Component{
    constructor(){
        super();
    }

    render(){
        return (
            <button 
                className="btn btn-primary"
                onClick={(this.props.click)}
                >
                {this.props.text}
            </button>
        )
    }
}

export default Button;