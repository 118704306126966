import React from "react";
import {Component} from "react";

import Value from "../Components/Value";
import main from "../Scripts/main";

class Line extends Component{
    constructor(){
        super();
    }//<Link to={"./editScaf/" + this.props.id}>

    click(){
        main.openLink("./editScaf/" + this.props.id);
    }

    render(){
        var values = [];
        for (var i in this.props.values){
            values.push(
                <Value value={this.props.values[i]} key={i}/>
            );
        }
        return (
            <tr
            onClick={this.click.bind(this)}
            style={{
                cursor: "pointer"
            }}
            >
                {values}
            </tr>
        )
    }
}

export default Line;