import logo from './logo.svg';
import './App.css';

import main from "./Scripts/main";
import Main from "./Pages/Main";
import { Component, createRef } from 'react';
import { BrowserRouter, Route, Routes , Link } from "react-router-dom";
import EditScaf from './Pages/EditScaf';
import Connection from "./Pages/Connection";
import ChangePassword from './Pages/ChangePassword';
import Admin from './Pages/Admin';
import ServerUnavailable from './Pages/ServerUnavailable';
import Reconnect from "./Pages/Reconnect"
import Menu from './Components/Menu';

class App extends Component{
	constructor(){
        super();

		this.alertBtn = createRef();
		this.alertText = createRef();
		this.link = createRef();
		this.pathLink = null;

		this.state = {
			dataList: []
		};
    }

	alert(txt){
		this.alertText.current.innerHTML = txt;
		this.alertBtn.current.click();
	}

	componentDidMount(){
		main.setAppVar(this);
	}

	componentDidUpdate(){
		if (this.pathLink != null){
			this.link.current.click();
			this.pathLink = null;
			this.forceUpdate();
		}
	}

	openLink(link){
		this.pathLink = link;
		this.forceUpdate();
	}

	render(){
		var pathLink = "";
		if (this.pathLink != null){
			pathLink = this.pathLink;
		}
		return (
			<div className='App'>
				<BrowserRouter>
					<Routes>
						<Route exact path="/" element={<Main dataList={main.getDataList()}/>}/>
						<Route exact path="/addScaf" element={<EditScaf />}/>
						<Route path="/editScaf/:id" element={<EditScaf />}/>
						<Route exact path="/connection" element={<Connection />}/>
						<Route path="/connection/:spec" element={<Connection />}/>
						<Route exact path="/changePassword" element={<ChangePassword />}/>
						<Route path="/changePassword/:spec" element={<ChangePassword />}/>
						<Route exact path="/admin" element={<Admin />}/>
						<Route exact path="/serverUnavailable" element={<ServerUnavailable />}/>
						<Route exact path="/reconnect" element={<Reconnect />}/>
					</Routes>
					<Link to={pathLink} ref={this.link} />
				</BrowserRouter>
				<button style={{display:"none"}} type="button" ref={this.alertBtn} className="btn btn-primary" data-toggle="modal" data-target="#alert">
                Launch static backdrop modal
                </button>

                <div className="modal fade" id="alert" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Information</h5>
                        </div>
                        <div ref={this.alertText} className="modal-body">
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal" >OK</button>
                        </div>
                        </div>
                    </div>
                </div>
			</div>
		);
	}
}



export default App;
